import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/schoolServe/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/schoolServe/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/schoolServe/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/schoolServe/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/schoolServe/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/schoolServe/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/schoolServe/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function serveList(formData) {
  return request.post(constant.serverUrl + "/base/schoolServe/serveList", formData);
}

function changeServe(formData) {
  return request.post(constant.serverUrl + "/base/schoolServe/changeServe", formData);
}

export default {
  pageList, create, edit, add, update, remove, batchRemove, serveList, changeServe
}