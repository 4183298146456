<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看场景值"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <el-divider></el-divider>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >刷新</el-button>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
      >
        <el-table-column prop="dictionaryName" label="权限图标名" width="180"></el-table-column>
        <el-table-column prop="status" label="是否启用" width="180">
          <template slot-scope="{row}">
            <span v-if="row.status" style="color:green">启用</span>
            <span v-else style="color:red">未启用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button v-if="row.status" size="mini" type="warning" @click="handleClose(row)">关闭</el-button>
            <el-button v-else size="mini" type="success" @click="handleOpen(row)">开启</el-button>
          </template>
        </el-table-column>
      </el-table>
      <schoolServe-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        :companyResult="companyResult"
        @close="onDetailModalClose"
      ></schoolServe-detail>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import schoolServeDetail from "./schoolServe-detail";
import schoolServeApi from "@/api/base/schoolServe";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["companyId"],
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      companyResult: [],
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },

    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("companyId", self.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      schoolServeApi
        .serveList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;

          self.tableData = jsonData.data;
          // self.totalPages = jsonData.totalPages;
          // self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    handleOpen(record) {
      var self = this;
      var formData = new FormData();
      formData.append("status", true);
      formData.append("dictionaryId", record.dictionaryId);
      formData.append("companyId", record.companyId);

      schoolServeApi.changeServe(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.changePage(self.pageIndex);

          self.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      });
    },
    handleClose(record) {
      var self = this;
      var formData = new FormData();
      formData.append("status", false);
      formData.append("dictionaryId", record.dictionaryId);
      formData.append("companyId", record.companyId);

      schoolServeApi.changeServe(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.changePage(self.pageIndex);

          self.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "schoolServe-detail": schoolServeDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>